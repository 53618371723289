/* SCSS RGB */
$rich-black-fogra-39: rgba(0, 5, 5, 1);
$english-violet: rgba(59, 51, 85, 1);
$dark-blue-gray: rgba(93, 93, 129, 1);
$light-steel-blue: rgba(191, 205, 224, 1);
$white: rgba(254, 252, 253, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(
  0deg,
  #000505ff,
  #3b3355ff,
  #5d5d81ff,
  #bfcde0ff,
  #fefcfdff
);
$gradient-right: linear-gradient(
  90deg,
  #000505ff,
  #3b3355ff,
  #5d5d81ff,
  #bfcde0ff,
  #fefcfdff
);
$gradient-bottom: linear-gradient(
  180deg,
  #000505ff,
  #3b3355ff,
  #5d5d81ff,
  #bfcde0ff,
  #fefcfdff
);
$gradient-left: linear-gradient(
  270deg,
  #000505ff,
  #3b3355ff,
  #5d5d81ff,
  #bfcde0ff,
  #fefcfdff
);
$gradient-top-right: linear-gradient(
  45deg,
  #000505ff,
  #3b3355ff,
  #5d5d81ff,
  #bfcde0ff,
  #fefcfdff
);
$gradient-bottom-right: linear-gradient(
  135deg,
  #000505ff,
  #3b3355ff,
  #5d5d81ff,
  #bfcde0ff,
  #fefcfdff
);
$gradient-top-left: linear-gradient(
  225deg,
  #000505ff,
  #3b3355ff,
  #5d5d81ff,
  #bfcde0ff,
  #fefcfdff
);
$gradient-bottom-left: linear-gradient(
  315deg,
  #000505ff,
  #3b3355ff,
  #5d5d81ff,
  #bfcde0ff,
  #fefcfdff
);
$gradient-radial: radial-gradient(
  #000505ff,
  #3b3355ff,
  #5d5d81ff,
  #bfcde0ff,
  #fefcfdff
);

body {

  #root {
    background-color: $light-steel-blue;

    #content {
      padding-top: 20px;
      min-height: 60vh;
      margin-bottom: 60px;
    }

    .col {
      h1 {
        text-align: center;
      }
    }
  }

  background-color: $rich-black-fogra-39;
  min-height: 100%;

  .nav {
    background-color: $rich-black-fogra-39;
    height: 50px;

    .nav-link {
      color: $light-steel-blue;
    }
    
    a {

      :hover {
        color: $english-violet;
      }
    }
  }

  footer {
    background-color: $rich-black-fogra-39;
    color: $light-steel-blue;
    text-align: center;
    padding: 30px;

    i {
      font-size: 2.4em;
    }

    .col {
      padding: auto;

      a {
        margin-right: 15px;
      }

      small {
        position: relative;
        top: 25%;
      }
    }
  }

  .portrait {
      width: 25%;
  }
  
  a {
    text-decoration: none;
    color: $light-steel-blue;

    :hover {
      color: $dark-blue-gray;
    }
  }
}
